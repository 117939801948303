.dash_container {
    height: 100vh;
    width: 100vw;
    background-color: var(--DB-MAIN-BG);
    position: relative;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
}

.dash_header {
    min-height: 48px;
    display: flex;
    border-bottom: 2px;
    /* background-color: #000; */
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    top: 0;
    padding: 0;
    margin: 0;
    padding: 0.1rem;
    /* background-color: rgb(61, 141, 189); 
    color: white;*/
    background-color: var(--DB-HEADER-BG);
}

.dash_header_logo_vt{
    /* background-image: url('../../public/img/VEETECH.png'); */
    background-image: url('../../public/img/cmplogo.png');
    height: 60px;
    width: 400px;
    background-size: contain;
    text-align: center;
    background-repeat: no-repeat;
    background-position: left top;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    /* transform: translateX(-50%); */
}
.dash_header_logo{
    background-image: url('../../public/img/cmplogo.png');
    height: 34px;
    width: 200px;
    background-size: cover;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: fixed;
    top: 4px;
    left: 2px;
    /* transform: translateX(-50%); */
}


.dash_header h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.dash_username {
    margin-top: 1em;
}



.dash_body_main {
    display: flex;
    background-color: var(--DB-MAIN-BG);
    /* min-height: calc(100vh - 4rem); */
    height: calc(100vh - 56px);
    width: 100%;
}

.dash_nav {
    width: 15rem;
    background-color: #f1f1f1;
    background-color: #c6c6c6;
    background-color: transparent;
    /* border-radius: 0.5rem; */
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 0.5rem;
    box-sizing: border-box;
    /* min-height: calc(100vh - 4rem); */
    height: calc(100vh - 56px);
    margin-top: 0;
    /* border-radius: 0.6rem; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);  */
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;
    outline: none;
    border: none;
}

.dash_nav.collapsed {
    width: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
}


.dash_nav_toggle_btn {
    position: fixed;
    left: 2px;
    top: 68.8px;
    z-index: 999;
    cursor: pointer;
    font-size: 1.5rem;
    border: none;
    background-color: transparent;
    outline: none;
}

.dash_nav_collapse_icon {
    border-radius: 0.6rem;
    font-size: 2.3rem;
    opacity: 0.2;
    color: rgb(61, 141, 189);
}

.dash_nav_collapse_icon:hover {
    opacity: 1;
    outline: none;
    /* background-color: aliceblue; */
    font-size: 2.4rem;
}

.dash_nav_expand_icon {
    border-radius: 0.6rem;
    font-size: 2.3rem;
    color: rgb(61, 141, 189);
}


.dash_body {
    /* min-height: calc(100vh - 4.3rem); */
    margin-left: 15rem;
    height: 100%;
    width: 100%;
    position: relative;
    /* border-radius: 0.6rem; */
    display: flex;
    transition: margin 0.3s ease-in-out;
    /* border: 1px solid rgb(198, 212, 255); */
    align-items: baseline;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    padding-top: 0.5rem;
    flex-direction: column;
    z-index: 1;
    overflow: hidden;
    /* box-shadow: */
    /* 1px 1px 0 #edadad,Top left shadow */
    /* 2px 2px 0 #edadad,Slightly darker top left shadow */
    /* 3px 3px 0 #edadad,Slightly darker top left shadow */
    /* -1px -1px 0 #edadad,Bottom right highlight */
    /* -2px -2px 0 #edadad;Slightly lighter bottom right highlight */

}

.dash_body.expanded {
    margin-left: 0.5rem;
}


