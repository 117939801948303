
.CustomAlert_back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
}
.OkCancel_back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.CustomAlert_content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    place-content: center;
}

.CustomAlert_content_title {

}

.CustomAlert_content_body{
    margin: 1rem;
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.CustomAlert_content_ok{
    background-color: rgb(95, 162, 95);
    width: 6rem;
    font-size: 1.2em;
    padding: 0.5rem;
    margin: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
}
.CustomAlert_content_cancel{
    background-color: rgb(177, 118, 114);
    width: 6rem;
    font-size: 1.2em;
    padding: 0.5rem;
    margin: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
}
.CustomAlert_content_bottom{
    display: flex;
    justify-content: space-around;
}
.CustomAlert_content_icon{
    font-size: 3rem;
}