@import "./icons.css";
@import "./dashlayout.css";
@import "./dashnav.css";
@import "./dashbody.css";
@import "./customalert.css";
@import "./sectionbox.css";
@import "./selectlistwithproperties.css";
@import "./SubForm.css";
@import "./CustomerAMC.css";
@import "./Searcher.css";
@import "./Notifier.css";
@import "./media.css";

/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap'); */


:root {
    /* COLORS */
    --HOVER-LINK-BG: #faebd7;
    --BODY-BUTTON-BG: #f8f8f8;
    --BODY-BUTTON-HOVER: #f8f8f8;
    --DB-TABLE-HEADER-BG: #e1dfdf;
    --DB-TABLE-TR-HOVER: #fafafa;
    --DB-INPUT-BODER-COLOR: #d6c1c1;
    --DB-INPUT-BODER-FOCUS-COLOR: #9c6363;
    --ERROR: firebrick;
    --DB-PULSELOADER-COLOR: #9fa1a1;
    --LOADER-SPINNER-COLOR: #9c6363;
    --DB-MAIN-BG: #c8cdcd;
    --DB-HEADER-BG: #c8cdcd;
    /* --DB-MAIN-BG: #ccc;
    --DB-HEADER-BG: #ccc; */
    --DB-ITEM-FOCUSED: #ffd092;
    --DB-ITEM-SELECTED: #ffd092;
    --DB-ITEM-SELECTED-BLUR: #ffe4c2;
    --DB-ITEM-SELECTED-BLUE: #c9e3ff;
    --DB-ITEM-CURSORLINE: #f1f8ff;
    --DB-ITEM-CURSORLINE-CHECKED: #d8e9fc;
    --DB-TITLEAREA-BLUE: #b3d5ff;
    --DB-PERIOD-COLOR: #3e61a9;
    --DB-TOPACTIONBUTTON-COLOR: #135ab0;
    --DB-TOPACTIONBUTTON-COLORt: #58a435;

}


body {
    font-family: Arial, sans-serif;
    /* font-family: 'Roboto Mono', monospace; */
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.padding_0 {
    padding: 0;
    margin: 0;
}

.hide_element {
    position: absolute;
    left: -99999px;
}

.flex_horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header_right_icons {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: bottom;
}

.flex_main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 48px);
    position: relative;
}

.flex_main_left {
    background-image: url("https://resources.tallysolutions.com/wp-content/themes/tally/assets/images/resources-home-page/insightful-reports.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    background-blend-mode: screen;
    margin: 1rem;
    width: 100%;
    min-height: calc(100vh - 4rem);
    opacity: 0.6;
}

.login_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 5rem);
    border-radius: 0.5rem;
    height: 50vh;
    padding: 0 1vw;
    /* background-color: rgba(61,141, 189, 0.1); */
    border: 1px solid #3d8dbd;
    opacity: 1;
    margin-right: 1rem;
    width: 18rem;
    padding-top: 2rem;
}

.login_input {
    margin-top: 0.4rem;
    border-radius: 0.5rem;
    outline: none;
    border: none;
    background-color: hwb(203 24% 26% / 0.05);
    height: 2rem;
    width: 15rem;
    padding-left: 0.5rem;
}



/* .login_input:focus{
    background-color: #ffe;
} */

.login_button_ {
    margin-top: 0.4rem;
    border-radius: 0.5rem;
    height: 2rem;
    border-color: darkgoldenrod;
    outline: none;
    width: 15rem;
}

.login_button {
    margin-top: 1rem;
    padding: 0.5em;
    width: 7.5rem;
    border-radius: 15px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #e1c331;
}

.login_persist {
    margin-top: 48px;
}

.position_right0 {
    position: absolute;
    right: 0;
}

.position_right1 {
    position: absolute;
    right: 1rem;
}

.margin_right_2 {
    margin-right: 2rem;
}

.message_available {
    color: rgb(64, 108, 64);
}

.message_error {
    color: var(--ERROR);
}

.in_form_add_btn {
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 5px 10px 0 10px;
    text-align: center;
    color: #7fa683;
    font-size: 1.5rem;
}

.in_form_remove_btn {
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 5px 10px 0 10px;
    text-align: center;
    color: #a68d7f;
    font-size: 1.5rem;
}

.in_form_ok_btn {
    width: 6rem;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #c0cdc0;
    font-weight: bold;
}

.in_form_cancel_btn {
    width: 6rem;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    background-color: #f9c6c6;
}

.grid_box_autofit {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    /* Adjust based on available space */
    grid-gap: 10px;
    justify-content: center;
    align-content: baseline;
    box-sizing: border-box;
}

.item_hover_scale_1_5:hover {
    scale: 1.5;
}