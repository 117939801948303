@media (max-width: 768px) {
    .dash_body {
        margin-left: 0.5rem;
    }

    .dash_nav {
        opacity: 1;
        background-color: var(--DB-MAIN-BG);
        z-index: 100;
    }

    .dash_header {
        background-color: var(--DB-HEADER-BG);
    }

    .header_right_icons {
        background-color: var(--DB-HEADER-BG);
    }
}

@media print {
    #dash_nav {
        visibility: hidden !important;
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }
    .dash_nav_toggle_btn{
        visibility: hidden !important;
    }

    #dash_body,
    #dash_body * {
        visibility: visible !important;
        left: 0;
        height: auto;
        margin-left: 0;
        overflow: visible;
    }


}