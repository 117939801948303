.section_box {
    position: relative;
    /* Required for positioning the pseudo-element */
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 5px;
    padding-top: 10px;
    display: inline-block;
    min-width: 100%;
}

.section_box.collborder {
    border: none;
    border-bottom: 1px solid #ccc;
}

.section_box.collborder p {
    color: #56a2fe;
}

.section_box p {
    cursor: pointer;
}

.section_box_header {
    position: absolute;
    opacity: 1;
    top: -11px;
    /* border: 1px solid #ccc; */
    margin-left: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
}

.section_box_content {
    transition: max-height 400ms ease, opacity 250ms ease;
}

.section_box_content.hide {
    opacity: 0;
    max-height: 0;
}


.section_box_sub_half {
    width: 31.1rem;
    padding-left: 2rem;
    background-color: '#eee'
}

