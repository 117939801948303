.SubForm_back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
    overflow: visible;
}

.SubForm_content_rel {
    position: relative;
    /* max-height: 75vh; */
    overflow-y: auto;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    overflow: visible;
}

.SubForm_content {
    
}