.SLWP_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 10vh;
}

.SLWP_add_edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: rgb(252, 252, 252);
    border-radius: 10px;
    padding: 10px;
    margin: 10px; */
}

.SLWP_list_container {
    display: flex;
}

.SLWP_select {
    overflow: auto;
    padding: 5px;
    border: none;
    outline: none;
    overflow: hidden;
    min-width: 13rem;
    cursor: pointer;
}

.SLWP_list_item_cont {
    padding: 6px;
    border-radius: 5px;
    font-size: 16px;
}

.SLWP_list_item {
    margin-bottom: 5px;
    border: 1px solid var(--DB-INPUT-BODER-COLOR);
    width: 18rem;
    padding: 10px;
    border-radius: 5px;
}

.SLWP_list_item.focus {
    background-color: var(--DB-ITEM-FOCUSED);
}

.SLWP_list_item_Select {
    width: 17rem;
}

.SLWP_list_cont_btnsticker_cont {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    /* min-width: 18rem; */
}

.SLWP_list_cont_btnsticker {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: #eee;
    min-width: 5rem;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(199, 46, 46, 0.1);
}

option:checked {
    background-color: var(--DB-ITEM-SELECTED-BLUR);
}

.SLWP_select option::selection {
    background-color: rgb(206, 206, 206);
}

.SLWP_list_cont {
    display: flex;
    flex-direction: column;
}

.SLWP_props_cont {}