.D_B_title_area {
    min-height: 3rem;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;
    /* border-bottom: 0.3px solid var(--DB-TITLEAREA-BLUE); */
    width: 100%;
}

.D_B_scroll_area {
    /* for scrolling */
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #fff #fff;
    scrollbar-gutter: none;
    height: calc(100%);
    width: 100%;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-top: 0;
    outline: none;
}

.D_B_table {
    width: 100%;
}

.D_B_scroll_area table,
td,
th {
    border-bottom: 1px solid #f8f8f8;
    border-collapse: collapse;
}

.D_B_table_head {
    /* background-color: #ccc; */
    /* background-color: var(--DB-TABLE-HEADER-BG); */
    background: linear-gradient(to bottom, #f1eded, var(--DB-TABLE-HEADER-BG), #f1eded);
    position: sticky;
    top: 0;
    z-index: 3;
    cursor: context-menu;
}

.D_B_table_th {
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
    height: 2rem;
    border-left: 1px solid var(--DB-TITLEAREA-BLUE);
}


/* .D_B_table_tbody {} */

.D_B_table_tr {
    height: 2.2rem;
    cursor: pointer;
}

.D_B_table_tr:hover {
    background-color: var(--DB-TABLE-TR-HOVER);
}

.D_B_table_tr_checked {
    background-color: var(--DB-ITEM-SELECTED-BLUE);
    height: 2.2rem;
    cursor: pointer;
}

.D_B_table_tr_cursorline {
    background-color: var(--DB-ITEM-CURSORLINE);
    height: 2.2rem;
    cursor: pointer;
}

.D_B_table_tr_cursorlinechecked {
    background-color: var(--DB-ITEM-CURSORLINE-CHECKED);
    height: 2.2rem;
    cursor: pointer;
}

.D_B_table_td {
    padding-left: 5px;
    padding-right: 5px;
    /* white-space: nowrap; */
    max-width: 18rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
tbody .D_B_table_td:hover{
    /* white-space: wrap; */
}

.D_B_table_td_edit {
    width: 3rem;
}

.D_B_table_td_edit button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
}

.D_B_table_td_edit_icon {
    font-size: 1.5rem;
}

.D_B_title_TopActionButton {
    width: 8rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding: 0.3rem;
    margin-right: 0.6rem;
    height: 100%;
    /* Replace 'red' with the desired border color */
    border-radius: 0.6rem;
    background-image: linear-gradient(to bottom, transparent, #fafafa);
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}

.D_B_title_TopActionButton:hover {
    border-radius: 0.6rem;
    background-color: var(--BODY-BUTTON-HOVER);
    border: none;
}

.D_B_title_TopActionButton:hover .D_B_title_TopActionButton_icon {
    /* font-size: 2.04rem; */
}

.D_B_title_TopActionButton span {
    padding-left: 0.3rem;
}

.D_B_title_TopActionButton_icon {
    font-size: 2rem;
}

.D_B_InputWithLabel {
    padding-bottom: 0.4rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    /* height: 2.4rem; */
    height: 100%;
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
}


.D_B_InputWithLabel input {
    padding: 0.4rem 0.6rem;
    margin: 0.2rem;
    border-radius: 0.3rem;
    outline: none;
    border: 1px solid var(--DB-INPUT-BODER-COLOR);
    background-color: transparent;
}

.D_B_InputWithLabel_span {
    padding: 0.4rem 0.6rem;
    margin: 0.2rem;
    border-radius: 0.3rem;
}

.D_B_InputWithLabel textarea {
    padding: 0.4rem 0.6rem;
    margin: 0.2rem;
    border-radius: 0.3rem;
    outline: none;
    border: 1px solid var(--DB-INPUT-BODER-COLOR);
    overflow: hidden;
    min-width: 8rem;
    max-width: 20rem;
    max-height: 10rem;
}

.D_B_InputWithLabel input[type="checkbox"] {
    transform: scale(1.5);
    padding: 0;
    margin: 0;
}

.D_B_InputWithLabel input:focus {
    border: 1px solid var(--DB-INPUT-BODER-FOCUS-COLOR);
}

.D_B_InputWithLabel_select {
    /* width: 18rem; */
    outline: none;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    margin-left: 2px;
}



.SpanWithLabel_cont {
    padding-bottom: 2px;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid rgb(248, 248, 248);
    height: 1.6rem;
}

.SpanWithLabel_label {
    display: block;
}

.SpanWithLabel_span {
    border-radius: 5px;
}

.D_B_FacilitiesEditGrid {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1x solid var(--);
}

.D_B_FacilitiesEditGrid h5 {
    border-bottom: 0.5px solid gray;
    display: contents;
    justify-content: space-around;
}

.D_B_FacilitiesEditGrid_table {
    margin: 0;
    border-radius: 1rem;
    display: flex;
    width: fit-content;
}

.D_B_FacilitiesEditGrid_th {
    background-color: var(--DB-TABLE-HEADER-BG);
    border-bottom: 0.5px solid var(--DB-TABLE-HEADER-BG);
    min-width: 6vw;
    text-align: center;
    border-collapse: collapse;
    padding: 0.4rem;
}

.D_B_FacilitiesEditGrid_th_row {
    background-color: var(--DB-TABLE-HEADER-BG);
    border-bottom: 0.5px solid var(--DB-TABLE-HEADER-BG);
    min-width: 6vw;
    font-weight: normal;
    text-align: left;
    border-collapse: collapse;
    padding: 0.4rem;
}

.D_B_FacilitiesEditGrid_tr {
    padding: 0.4rem 0;
}

.D_B_FacilitiesEditGrid_td {
    text-align: center;
    min-width: 4rem;
    text-align: center;
    border-collapse: collapse;
    padding: 0.5rem 0;
}

.D_B_FacilitiesEditGrid_td_view {
    text-align: center;
    min-width: 4rem;
    text-align: center;
    border-collapse: collapse;
    padding: 0.5rem 0;
}

.D_B_FacilitiesEditGrid_td:hover input[type="checkbox"] {
    transform: scale(2.5);
    outline: none;
    background-color: var(--DB-TABLE-TR-HOVER);
    border: 1px solid var(--DB-INPUT-BODER-FOCUS-COLOR);
}



.D_B_FacilitiesEditGrid_input {
    transform: scale(1.5);
    width: 12px;
    height: 12px;
    border: 1px solid var(--DB-INPUT-BODER-FOCUS-COLOR);
    /* Set the desired border color */
    border-radius: 3px;
    /* Add rounded corners to the checkbox */
    outline: none;
    cursor: pointer;
}

.D_B_radio_hover{
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}
.D_B_radio_hover:hover{
    scale: 2;
}

.D_B_error {
    margin-top: 1rem;
    color: var(--ERROR);
}

.D_B_main_title {
    display: flex;
    justify-content: center;
    position: relative;
}

.D_B_main_title_h {
    border-bottom: 1px solid black;
    padding: 0;
    margin: 0;
    margin-bottom: 0.3rem;
    font-size: 1rem;
    border-bottom: 1px solid #eee;
}



.row_selector_div {
    display: flex;
    place-content: center;
    border-radius: 50%;
    background-color: transparent;
    width: 30px;
    height: 30px;
    transition: scale 400ms ease, background-color 400ms ease;
    cursor: default;
}

.row_selector_div input[type="checkbox"] {
    cursor: default;
}

.row_selector_div:hover {
    background-color: var(--DB-ITEM-SELECTED-BLUE);
    scale: 1.3;
}

.row_selector_thtd {
    width: 2.7rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 2.2rem;
    text-align: left;
    vertical-align: middle;
}

.calls_status_tab {
    width: 100%;
    /* max-width: 9rem; */
    place-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    padding-bottom: 5px;
    /* margin-left: 5px; */
    display: flex;
    cursor: pointer;
    overflow: hidden;
    /* color: var(--DB-TOPACTIONBUTTON-COLOR); */
    color: gray;
    /* border-bottom: 3px solid var(--DB-ITEM-SELECTED); */
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    /* border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; */
    background-color: transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.calls_status_tab_sel {
    background-color: var(--DB-ITEM-SELECTED);
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}

.calls_status_tab:hover {
    background-color: var(--HOVER-LINK-BG);
}

.calls_status_tab_sel:hover {
    background-color: var(--DB-ITEM-SELECTED);
}