.dash_nav_items {
    display: block;
    padding-top: 3rem;
    height: 100%;
}

.dash_nav_item {
    width: 100%;
    min-height: 48px;
    /* height: 3rem; */
    text-align: center;
    text-align: left;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    border-radius: 1rem;
}

.dash_nav_item_focused {
    width: 100%;
    min-height: 48px;
    text-align: center;
    text-align: left;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    background-color: #ffd092;
    border-radius: 1rem;
}

.dash_nav_item:hover {
    background-color: var(--HOVER-LINK-BG);
}

.dash_nav_icon {
    font-size: 2rem;
    color: #00488b;
}

.dash_nav_title {
    padding-left: 0.5rem;
}