.cust_amc_container {
    width: 100%;
    min-height: 10vh;
}

.cust_amc_ctrl_btns {
    display: flex;
}


.cust_amc_list {
    width: fit-content;
    border: 1px solid var(--DB-INPUT-BODER-COLOR);
    border-radius: 5px;
}

.cust_amc_table {
    margin-bottom: 2px;
    border-radius: 5px;
}

.cust_amc_table tbody tr{
    cursor: pointer;
}

.cust_amc_table thead tr{
    background-color: var(--DB-TABLE-HEADER-BG);
}


.cust_amc_table tbody tr:hover {
    background-color: var(--DB-TABLE-TR-HOVER);
}

.cust_amc_table_td_th {
    padding: 5px 1rem;
    text-align: center;
    font-size: 0.8em;
}
.cust_amc_table_td_th_Left {
    padding: 5px 1rem;
    text-align: left;
    font-size: 0.8em;
}

