.edittablecolumns_movebuttons {
    border: 1px solid #eee;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    padding: 5px;
    font-weight: bolder;
    background-color: #f0f0f0;
}

.edittablecolumns_movebuttons:hover {
    background-color: goldenrod;
}