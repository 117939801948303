.searcher_main {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.3rem;
    padding-right: 0.5rem;
    margin-right: 0.6rem;
    height: 3rem;
    border-radius: 0.6rem;
    background-image: linear-gradient(to bottom, transparent, #fafafa);
    min-width: 3rem;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
    outline: none;
    transition: width 0.3s ease;
}



.searcher_main:hover {
    background-color: var(--BODY-BUTTON-HOVER);
    border: none;
}

.searcher_main:hover .searcher_icon {
    /* font-size: 1.74rem; */
}


.searcher_icon {
    font-size: 1.7rem;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}

.searcher_clear_btn {
    font-size: 1.5em;
    margin-top: 0.4rem;
    display: block;
}

.searcher_advance_btn {
    font-size: 1.5rem;
    display: none;
    padding: 0, 5rem
}

.searcher_advance_box {
    min-width: 50vw;
    position: absolute;
    top: 3rem;
    left: 0;
    background-color: #fafafa;
    z-index: 4;
    display: none;
    border-radius: 10px;
    border: 0.3px solid var(--DB-TITLEAREA-BLUE);
}

.searcher_rel {
    position: relative;
}

.searcher_adv_tabs_cont {
    overflow-y: auto;
    max-height: 50vh;
    min-height: 50vh;
}

.searcher_adv_tabs_cont2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 3rem;
    position: sticky;
    top: 0;
    width: 100%;
}

.searcher_adv_tab {
    display: grid;
    justify-content: center;
    align-content: center;
    height: 2.5rem;
    background-color: #eee;
    transition: background-color 200ms ease;
}

.searcher_adv_tab:hover {
    background-color: var(--HOVER-LINK-BG);
}

.searcher_adv_items_picker {
    min-height: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Adjust based on available space */
    grid-gap: 10px;
    justify-content: center;
    align-content: baseline;
    padding: 10px;
    box-sizing: border-box;
    z-index: 0;
}

.searcher_adv_item_pick {
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 2.5rem;
    grid-gap: 5px;
    cursor: pointer;
    /* transition: background-color 200ms ease; */
    border: 1px solid #ddd;
}

.searcher_adv_item_pick:hover {
    border: 1px solid var(--DB-TOPACTIONBUTTON-COLOR);
    background-color: var(--HOVER-LINK-BG);
    border-radius: 10px;
}

.searcher_adv_compare_pick_wrap {
    display: block;
    border: 1px dashed #ddd;
    border-radius: 5px;
}

.searcher_adv_compare_pick {
    display: grid;
    height: 3rem;
    place-content: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 200ms ease;
    border-top: 1px dashed #eee;
}

.searcher_adv_compare_pick:hover {
    background-color: var(--HOVER-LINK-BG);
}

.searcher_value_box {
    border: none;
    outline: none;
    width: 20vw;
    padding: 0.5rem;
    border-radius: 10px;
    margin-right: 1rem;
}

.searcher_control_btns_box {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.2rem;
}


.searcher_rules_view {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 2px;
}

.searcher_rules_item {
    display: flex;
    position: relative;
    padding-top: 3px;
}
.searcher_rules_item_del{
    color:red;
    display:grid;
    place-items:self-end;
    cursor:pointer;
    background-color:#eee;
    position: absolute;
    bottom: 0;

}

.searcher_rules_item_del:hover{
    font-size: 1.6rem;
    font-weight: bolder;
    bottom: -3px;
    left: -3px;
    border-radius: 50%;
}



/* <Search Button>*/
.search_button_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
    padding-right: 0.5rem;
    margin-right: 0.6rem;
    height: 3rem;
    border-radius: 0.6rem;
    background-image: linear-gradient(to bottom, transparent, #fafafa);
    min-width: 3rem;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}

.search_button_icon {
    font-size: 1.7rem;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}


.search_button_input {
    outline: none;
    transition: width 0.3s ease;
    min-width: none;
    width: 0;
    padding: 0.5rem;
    margin: 0;
    font-size: 1.2em;
    border: none;
    position: inherit;
    background-color: transparent;
    color: var(--DB-TOPACTIONBUTTON-COLOR);
}

.search_button_input_exp {
    outline: none;
    transition: width 0.3s ease;
    min-width: none;
    width: 20vw;
    padding: 0.5rem;
    margin: 0;
    font-size: 1.2em;
    border: none;
}


.search_button_input:focus {
    border-radius: 0.6rem;
    padding: 0.5rem;
}

.search_button_div:hover {
    background-color: var(--BODY-BUTTON-HOVER);
    border: none;
}

.search_button_div:hover .search_button_icon {
    /* font-size: 1.74rem; */
}


/* </Search Button> */
