
.icon_button {
    width: 48px;
    height: 48px;
    font-size: 2rem;
    background-color: transparent;
    border: none;
    color: var(--COLOR);
    display: grid;
    place-content: center;
    align-items: flex-end;
  }
  
  
  .icon_button[disabled] {
    visibility: hidden;
  }

  

.icon_home{
    /* font-size: 1.7rem; */
    /* color: #e1c331; */
    margin-right: 2rem;
}

.icon_user{
    /* font-size: 1.7rem; */
    /* color: #e1c331; */
}

.icon_logout{
    /* font-size: 1.7rem; */
    /* color: #e1c331; */
    margin-right: 1rem;
}

